(function($) {
	"use strict"

		// Masonry blog posts
		$(window).load(function(){
			if ($('#masonry').length){	
				var $container = $('#masonry');
				// initialize
				$container.masonry({
					itemSelector: '.post',
					percentPosition: true
				});
			}
		});

		// Gallery filter
		var filterList = {
			init: function () {
				$('#portfoliolist').mixItUp({
						selectors: {
						target: '.portfolio',
						// filter: '.filter'
					}
				});
			}
		};

		filterList.init();
		$('#filters li:first-child span').addClass('active');


	  	// Fakes the loading setting a timeout
	    setTimeout(function() {
	        $('body').addClass('loaded');
	    }, 2000);

		// Enable animations
		new WOW().init();

		// Enable Headhesive
		var options = {
		    offset: 775,
		    classes: {
		        clone:   'banner--clone',
		        stick:   'banner--stick',
		        unstick: 'banner--unstick'
		    }
		};

		// Initialise with options
		var banner = new Headhesive('.navbar', options);

	    // Show more services
		$('.service').slice(0, 6).addClass('show');
	    //$(".btn").on('click', function (e) {
	    //    e.preventDefault();
	        //$(".service:hidden").slice(0, 6).slideDown().show();
	    //});
		
	    // Equal height
		$('.service, .equalHeight').matchHeight();

	    // Blur images on scroll
		var pxlCount = 0
		$(window).on('scroll', function () {
		    pxlCount = $(document).scrollTop()/75;
		    $(".page-title > img, .item > img").css({"-webkit-filter": "blur("+pxlCount+"px)","-moz-filter": "blur("+pxlCount+"px)","filter": "blur("+pxlCount+"px)" }); 
		});

		// Accordion
		if ($('.accordion').length){
			$( ".accordion" ).accordion();
		}

		// Slick slider
		if ($('.logos.four-cols').length){
			$('.logos.four-cols').slick({
				infinite: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				autoplay: true,
	  			autoplaySpeed: 1500,
	  			arrows: false,
	  			responsive: [
				    {
				      breakpoint: 991,
				      settings: {
				        slidesToShow: 3,
				        slidesToScroll: 1
				      }
				    },
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 2,
				        slidesToScroll: 1
				      }
				    },
				    {
				      breakpoint: 480,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1
				      }
				    }
				]
			});
		}

		if ($('.slide.four').length){
			$('.slide.four').slick({
				infinite: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				nextArrow: '<i class="fa fa-chevron-right"></i>',
				prevArrow: '<i class="fa fa-chevron-left"></i>',
	  			responsive: [
	  				{
				      breakpoint: 991,
				      settings: {
				        slidesToShow: 3,
				        slidesToScroll: 1
				      }
				    },
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 3,
				        slidesToScroll: 1
				      }
				    },
				    {
				      breakpoint: 480,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1
				      }
				    }
				]
			});
		}

		if ($('.slide.three-cols').length){
			$('.slide.three-cols').slick({
				infinite: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				nextArrow: '<i class="fa fa-chevron-right"></i>',
				prevArrow: '<i class="fa fa-chevron-left"></i>',
	  			responsive: [
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 2,
				        slidesToScroll: 1
				      }
				    },
				    {
				      breakpoint: 480,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1
				      }
				    }
				]
			});
		}

		if ($('.slide.one-col').length){
			$('.slide.one-col').slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				nextArrow: '<i class="fa fa-chevron-right"></i>',
				prevArrow: '<i class="fa fa-chevron-left"></i>'
			});
		}

		// Number counter
		$('.counter span').counterUp({
            delay: 10,
            time: 500
        });

		// Venobox image modal
		$('.venobox').venobox({
	        titleattr: 'data-title',    // default: 'title'
	        numeratio: true,            // default: false
	        infinigall: true            // default: false
	    });

		// Google maps
        if ($("#map").length > 0){
        	
 			$("#map").gmap3({                        
                map:{
                    options:{
                        zoom: 16,
                        center: new google.maps.LatLng(-29.6303747,-51.4028157),
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        mapTypeControlOptions: {
                            mapTypeIds: [google.maps.MapTypeId.ROADMAP, "style1"]
                        },
                        styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"poi.business","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit.station.airport","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit.station.bus","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#cfe5ef"},{"visibility":"on"}]}],
                        streetViewControl: false,
                        draggable: true,
                        scrollwheel: false,
                    }
                },

                marker:{
                    latLng:[-29.6303747,-51.4028157],
                    options:{
                        icon: new google.maps.MarkerImage("images/pin.png", new google.maps.Size(74, 97, "px", "px")),
                    }
                }

            });

        }


})(jQuery);