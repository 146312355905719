$(document).ready(function () {

    $('[name="form-contact"]').validate({
        rules: {
            name: {
                required: true,
                maxlength: 150
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                required: true,
                maxlength: 20
            },
            message: {
                required: true,
                maxlength: 500
            }

        },
        messages: {
            name: {
                required: "Campo obrigatório.",
                maxlength: "O campo nome deve conter no máximo 150 caracteres."
            },
            email: {
                required: "Campo obrigatório.",
                email: "O campo email deve conter um email válido."
            },
            phone: {
                required: "Campo obrigatório.",
                maxlength: "O campo nome deve conter no máximo 20 caracteres."
            },
            message: {
                required: "Campo obrigatório.",
                minlength: "O campo mensagem deve conter no mínimo 10 caracteres.",
                maxlength: "O campo mensagem deve conter no máximo 500 caracteres."
            }
        },
        errorClass: 'has-error',
        validClass: 'has-success',
        errorElement: 'span',
        success: function (label) {

            // $("#submit-form").html("Enviando...");
            // $("#submit-form").attr("disabled", "disabled");

        },
        submitHandler: function(form) {
             $("#submit-form").html("Enviando...");
            $("#submit-form").attr("disabled", "disabled");
            //form.submit();
        }
    });
    // Mascaras input página trabalhe contato
    $(document).ready(function () {
        //$('.telefoneTrabalheContato').mask("(99) 9999-9999");

        var SPMaskBehavior = function (val) {
                return val.replace(/\D/g, '').length === 11 ? '00 00000-0000' : '00 0000-00009';
            },
            spOptions = {
                onKeyPress: function(val, e, field, options) {
                    field.mask(SPMaskBehavior.apply({}, arguments), options);
                }
            };

        $('[name="phone"]').mask(SPMaskBehavior, spOptions);
    });
});