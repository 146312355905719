$('[name="form-order"]').submit(function (e) {
//function contactSubmit() {
    e.preventDefault();

    //Informações do formulário de contato
    var form = $(this).serialize();

    $.ajax({
        url: "/order/send",
        method: "POST",
        data: form,
        headers: {
            "X-CSRF-Token": $('[name="_token"]').val()
        }
    }).done(function (response) {

        //jQuery('[name="form-contact"]').reset();

        if (response == true) {
            $("#message-success").show();
            $('[name="form-order"]').trigger('reset');

            setTimeout(function () {
                $("#message-success").hide();
            }, 20000);

        }else{
            $("#message-error").show();
        }

        $("#submit-form").html("Enviar");
        $("#submit-form").removeAttr("disabled");
    });

});